.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: auto;
  outline: none;
  width: 100%;
  z-index: 10;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  z-index: 10;
  background: rgba(173, 183, 199, 0.53) !important;
}
.ReactModal__Body--open {
  overflow-y: hidden;
}
.ant-select-item-option {
  display: flex !important;
  gap: 5px !important;
}
.ant-select-item-option-content {
  display: flex !important;
  gap: 5px !important;
}
.ReactModal__Overlay {
  transition: opacity 500ms ease-in-out !important;
}
.ReactModal__Overlay--before-close {
  opacity: 0;
}
.myoverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.54);
  backdrop-filter: blur(17px);
  -webkit-backdrop-filter: blur(17px);
  transition: ease-in 5s !important;
}

.modalInitial {
  animation-name: isActive;
  animation-duration: 1s;
  animation-fill-mode: both;

  /* animation-direction: alternate-reverse; */
}

@keyframes isActive {
  0% {
    transform: translateX(-30vw);
  }
  100% {
    transform: translateX(0vw);
  }
}
.modalInitial.disabled {
  animation-name: isDisabled;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes isDisabled {
  0% {
    transform: translateX(-30vw);
  }
  100% {
    transform: translateX(0vw);
  }
}
.Toastify__toast-theme--light {
  @media (max-width: 767px) {
    width: 81.133vw;
    margin-left: 17.867vw;
  }
}

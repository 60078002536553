body {
  margin: 0;
  font-family: 'Outfit', 'Mulish', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
  font-family: 'OutfitSemiBold';
  src: local('OutfitSemiBold'), url('assets/fonts/Outfit-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'OutfitRegular';
  src: local('OutfitRegular'), url('assets/fonts/Outfit-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'OutfitMedium';
  src: local('OutfitMedium'), url('assets/fonts/Outfit-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'OutfitBold';
  src: local('OutfitBold'), url('assets/fonts/Outfit-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'OutfitExtraLight';
  src: local('OutfitExtraLight'), url('assets/fonts/Outfit-ExtraLight.ttf') format('truetype');
  font-weight: 200;
}

@font-face {
  font-family: 'OutfitLight';
  src: local('OutfitLight'), url('assets/fonts/Outfit-Light.ttf') format('truetype');
  font-weight: 300;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.transform-component-module_content__2jYgh {
  display: block !important;
  width: 100% !important;
}
.transform-component-module_wrapper__1_Fgj {
  width: 100% !important;
}

.wallet {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.react-flow__handle.connectable {
  background-color: rgba(38, 119, 233, 0.15) !important;
  opacity: 0.1;
}

.react-flow__edge-path {
  stroke: #2677e9 !important;
  stroke-width: 1 !important;
}

.image {
  position: absolute;
  top: -20px;
  left: 54px;
  width: 33px;
}
.currency {
  margin-top: 10px;
  font-family: 'OutfitMedium';
  font-size: 14px;
  line-height: 20px;
}

.react-flow__node-default.selected,
.react-flow__node-group.selected,
.react-flow__node-input.selected,
.react-flow__node-output.selected {
  box-shadow: none !important;
}
.percentage {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
}
.amount {
  color: #1bb691;
  font-family: 'OutfitSemiBold';
  font-size: 19px;
  line-height: 24px;
}
.name {
  font-family: 'OutfitSemiBold';
  font-size: 19px;
  line-height: 24px;
}
.Responsibility {
  font-family: 'OutfitRegular';
  font-size: 14px;
  line-height: 24px;
}
.walletName {
  font-size: 12px;
  line-height: 24px;
  font-family: 'OutfitMedium';
}
.amount1 {
  font-family: 'OutfitSemiBold';
  font-size: 19px;
  line-height: 24px;
  color: #ffffff;
}

.react-flow__attribution.right {
  display: none !important;
}

.subscriber {
  background-color: red;
  transform: translateX(0vw);
}

.exist {
  background-color: green;
  transform: translateX(-20vw);
}
.Toastify__toast {
  font-size: 16px;
}
